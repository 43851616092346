.cardContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cardContainer div.infoContainer {
    display: flex;
    flex-direction: column;
}

.cardContainer div.infoContainer p {
    font-size: 14px;
    margin: 0;
}

.laboratoryNote--container {
    display: flex;
    align-items: center;
    align-self: stretch;
    background: white;
    border-radius: 15px;
    margin-top: 5px;
    padding: 10px;
    gap: 10px;
    width: 100%;
}

.laboratoryNote__span {
    color: black;
    font-size: 14px;
    font-weight: 700;
}
